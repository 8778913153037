import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { useGate } from 'statsig-react'
import withCookies from '../components/HOC/withCookies'
import withLayout from '../components/HOC/withLayout'
import AuthenticatedRoute from '../components/atoms/AuthenticatedRoute'
import NonAuthenticatedRoute from '../components/atoms/NonAuthenticatedRoute'
import { ROUTES_NAMES } from '../routes/constants'
import NotFound from '../routes/notFound'
import { quickLoadable } from './helpers.routers'

const Login = quickLoadable(() => import(/* webpackChunkName: "login" */ '../containers/login'))

const Enterpriselogin = quickLoadable(() => import(/* webpackChunkName: "enterpriselogin" */ '../containers/enterpriselogin'))

// reusing the same enterpriselogin container w/ or w/o team
const SignUpWithSSOTeam = quickLoadable(() => import(/* webpackChunkName: "enterpriselogin" */ '../containers/enterpriselogin'))
const SignInWithIdP = quickLoadable(() => import(/* webpackChunkName: "idpauth" */ '../containers/idpauth'))

const Splash = quickLoadable(() => import(/* webpackChunkName: "signup" */ '../containers/signup'))

const WelcomeBack = quickLoadable(() => import(/* webpackChunkName: "welcomeBack" */ '../containers/welcomeBack'))

const IntroduceYourself = quickLoadable(() => import(/* webpackChunkName: "IntroduceYourself" */ '../containers/introduceYourself'))

const AcceptTos = quickLoadable(() => import(/* webpackChunkName: "IntroduceYourself" */ '../containers/acceptTos'))

const SignUpWithTeam = quickLoadable(() => import('../containers/singupWithTeam'))

// OLD UNAUTH
const Authenticated = quickLoadable(() => import(/* webpackChunkName: "app" */ './AuthenticatedRouter'))

const ConfirmSignUp = quickLoadable(() => import(/* webpackChunkName: "confirmSignup" */ '../containers/confirmSignUp'))

const ForgotPassword = quickLoadable(() => import(/* webpackChunkName: "forgotPassword" */ '../containers/forgotPassword'))

const ForgotPasswordSubmit = quickLoadable(() => import(/* webpackChunkName: "forgotPasswordSubmit" */ '../containers/forgotPasswordSubmit'))

const ReportRequest = quickLoadable(() => import(/* webpackChunkName: "ReportRequest" */ '../routes/reportRequest'))

const Report = quickLoadable(() => import(/* webpackChunkName: "report" */ '../routes/report'))

const ProjectFor = quickLoadable(() => import(/* webpackChunkName: "report" */ '../routes/projectFor'))

const Project = quickLoadable(() => import(/* webpackChunkName: "report" */ '../routes/project'))

const Routes = () => {
  const { value: show_4p_templates } = useGate('show_4p_templates')

  return (
    <Switch>
      <NonAuthenticatedRoute exact path={ROUTES_NAMES.splash.route} component={Splash} />
      {/* redirect from any old /main to a non /main route */}
      <Route path={ROUTES_NAMES.legacy.route} render={props => <Redirect to={props.location.pathname.replace('/main', '')} />} />
      {/* routes used for testing */}
      <Route exact path={ROUTES_NAMES.report.route} component={ReportRequest} />
      {/* routes only accessible by signed out users */}
      <NonAuthenticatedRoute exact path={ROUTES_NAMES.welcomeBack.route} component={withCookies(WelcomeBack)} />
      <NonAuthenticatedRoute exact path={ROUTES_NAMES.enterpriselogin.route} component={withCookies(Enterpriselogin)} />
      <NonAuthenticatedRoute exact path={ROUTES_NAMES.login.route} component={withCookies(Login)} />
      <NonAuthenticatedRoute exact path={ROUTES_NAMES.confirmSignup.route} component={withCookies(ConfirmSignUp)} />
      <NonAuthenticatedRoute exact path={ROUTES_NAMES.SignupWithTeam.route} component={withCookies(SignUpWithTeam)} />
      <NonAuthenticatedRoute exact path={ROUTES_NAMES.SignupWithSSOTeam.route} component={withCookies(SignUpWithSSOTeam)} />
      <NonAuthenticatedRoute exact path={ROUTES_NAMES.SignInWithIdP.route} component={withCookies(SignInWithIdP)} />
      <NonAuthenticatedRoute exact path={ROUTES_NAMES.forgotPassword.route} component={withCookies(ForgotPassword)} />
      <NonAuthenticatedRoute exact path={ROUTES_NAMES.forgotPasswordSubmit.route} component={withCookies(ForgotPasswordSubmit)} />
      {show_4p_templates && <Route path={`${ROUTES_NAMES.report.route}/for`} component={withLayout(ProjectFor)} />}
      {show_4p_templates && <Route path={`${ROUTES_NAMES.report.route}/:number`} component={withLayout(Project)} />}
      {!show_4p_templates && <Route path={`${ROUTES_NAMES.report.route}/:number`} component={withLayout(Report)} />}
      <AuthenticatedRoute path={ROUTES_NAMES.introduceYourself.route} component={IntroduceYourself} exact />
      <AuthenticatedRoute path={ROUTES_NAMES.acceptTos.route} component={AcceptTos} exact />
      <Route path={ROUTES_NAMES.app.route} component={Authenticated} />
      <Route component={NotFound} />
    </Switch>
  )
}

export default Routes
