import { connectRouter } from 'connected-react-router'
import { combineReducers } from 'redux'
import auth from './auth'
import forgot from './forgot'
import profile from './profile'
import reports from './reports'
import signupReducer from './signup'
import walkthrough from './walkthrough'

const RESET = 'app/RESET'

const appReducer = history =>
  combineReducers({
    router: connectRouter(history),
    auth,
    signup: signupReducer,
    forgot,
    reports,
    profile,
    walkthrough
  })

const rootReducer = history => (state, action) => {
  if (action.type === RESET) {
    state = undefined
  }

  return appReducer(history)(state, action)
}

export default rootReducer

export const reset = () => async (dispatch) => {
  dispatch({
    type: RESET
  })

  const shown = localStorage.getItem('walkthroughShown')
  localStorage.clear()
  sessionStorage.clear()
  if (shown) localStorage.setItem('walkthroughShown', shown)
}
