import { useEffect, useRef } from 'react'

function useURLEnv() {
  const href = useRef(null)

  useEffect(() => {
    const bodyList = document.querySelector('body')
    const observer = new MutationObserver((mutations) => {
      mutations.forEach(() => {
        if (['beta', 'dev'].includes(process.env.REACT_APP_ENV) && !window.location.search.includes(`env=${process.env.REACT_APP_ENV}`)) {
          const searchParams = new URLSearchParams(window.location.search)
          searchParams.append('env', process.env.REACT_APP_ENV)
          window.history.replaceState({}, window.title, `${window.location.origin + window.location.pathname}?${searchParams.toString()}`)

          return
        }
        if (!['beta', 'dev'].includes(process.env.REACT_APP_ENV) && !window.location.search.includes(`occ=p`)) {
          const searchParams = new URLSearchParams(window.location.search)
          searchParams.append('occ', 'p')
          window.history.replaceState({}, window.title, `${window.location.origin + window.location.pathname}?${searchParams.toString()}`)

          return
        }
        const newHref = window.location.href
        if (newHref !== href.current) {
          href.current = newHref
          const t = new Event('friendbuy-location-update')
          window.dispatchEvent(t)
        }
      })
    })
    const config = {
      childList: true,
      subtree: true
    }
    observer.observe(bodyList, config)

    return () => observer.disconnect()
  }, [])
}

export default useURLEnv
