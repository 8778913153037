import { PlanBanner as UIPlanBanner } from '@oneclickdata/components'
import React from 'react'
import { useSelector } from 'react-redux'

const sSecs = 86400
const TIMESTAMP_DIGIT_COUNT = 13
/**
 * PlanBanner component
 * @param {Object} props
 * @param {Object} props.plan - Plan object
 * @param {string} [props.subtitle] - Subtitle to display
 * @param {boolean} [props.showPrice] - Show price
 * @param {'monthly' | 'annual'} [props.billingPeriod] - Billing period
 * @param {function} [props.onBillingPeriodChange] - Billing period
 * @param {boolean} [props.showBillingSwitch] - Show billing switch
 * @param {boolean} [props.showExpired] - Show expired
 * @param {boolean} [props.showRenewTag] - Show renew tag
 * @param {boolean} [props.showDaysLeft] - Show days left
 * @param {boolean} [props.showMonthlyProjects] - Show monthly projects
 * @param {boolean} [props.showBorder] - Show monthly projects
 *
 */
const PlanBanner = ({
  plan,
  subtitle = '',
  showPrice = false,
  billingPeriod,
  onBillingPeriodChange,
  showBillingSwitch,
  showExpired = false,
  showRenewTag = false,
  showDaysLeft = false,
  showMonthlyProjects = false,
  showBorder = false
}) => {
  const { signUpTimestamp } = useSelector(state => state.profile)
  if (!plan?.sku) {
    return null
  }
  let totalDays
  let remainingDays
  let reDate
  const planDuration = plan?.limits?.free?.period?.ms
  const liveTimestamp = plan?.details?.billing_cycle_anchor == null ? signUpTimestamp : plan?.details?.billing_cycle_anchor
  if (planDuration) {
    const planEndDate = plan?.limits?.end_date ? new Date(plan.limits.end_date) : new Date((plan?.start_time || signUpTimestamp) + planDuration)
    const differenceInTime = planEndDate - Date.now()
    remainingDays = Math.round(differenceInTime / (1000 * 3600 * 24))
    remainingDays = remainingDays > 0 ? remainingDays : 0
    totalDays = Math.round(planDuration / (1000 * 3600 * 24))
    reDate = planEndDate.setDate(planEndDate.getDate() + totalDays)
  } else if (liveTimestamp > 0) {
    // calculate plan's date details given the initial activity TS
    totalDays = plan.billingPeriod === 'annual' ? 365 : 30
    let anchorTs = liveTimestamp
    // Some timestamps are in seconds, some are in milliseconds
    if (anchorTs.toString().length < TIMESTAMP_DIGIT_COUNT) {
      anchorTs = liveTimestamp * 1000
    }
    reDate = new Date(Math.floor(new Date(anchorTs)) + totalDays * sSecs)
  }
  const reTag = new Date(reDate).toLocaleDateString('en-us', { month: 'short', day: 'numeric' })

  let price = undefined
  if (plan?.price) {
    let period = plan.billingPeriod ?? billingPeriod
    if (period) {
      price = plan.price[period === 'annual' ? 'annual' : 'monthly']
    } else {
      price = plan.price.annual ?? plan?.price?.monthly
    }
  }

  let color = plan?.ui?.color
  if (!color) {
    const purchaseUiTitle = plan?.ui?.purchase_confirmation_ui?.title || plan.purchase_confirmation_ui?.title
    color = purchaseUiTitle?.displayTitleColor
  }

  const isExpired = plan?.is_expired || false
  let leftSubtitle = subtitle

  if (showMonthlyProjects) {
    leftSubtitle = `${plan?.limits?.monthly?.code_reports?.quantity} projects/mo.`
  }

  if (isExpired && showExpired) {
    leftSubtitle = 'Expired'
  }

  const title = plan?.orderDisplayTitle || plan?.title || plan?.ui?.title
  const hidePrice = plan?.ui?.hide_price || false

  return (
    <UIPlanBanner showBorder={showBorder}>
      <UIPlanBanner.LeftSection>
        <UIPlanBanner.Title color={color ?? '$cyan300'}>{title}</UIPlanBanner.Title>
        {leftSubtitle && <UIPlanBanner.Subtitle>{leftSubtitle}</UIPlanBanner.Subtitle>}
      </UIPlanBanner.LeftSection>
      <UIPlanBanner.RightSection>
        {showPrice && !isExpired && price && !hidePrice && <UIPlanBanner.Price value={price.value} frequency={price.frequency === 'mo' ? 'monthly' : 'annual'} />}
        {showDaysLeft && <UIPlanBanner.Counter showRemaining={false} showLockOnLimit={false} value={remainingDays ?? 0} total={totalDays ?? 0} label="Days Left" />}
        {showRenewTag && !isExpired && <UIPlanBanner.Subtitle>Renews {reTag}</UIPlanBanner.Subtitle>}
        {showBillingSwitch && <UIPlanBanner.BillingSwitch value={billingPeriod} onChange={onBillingPeriodChange} />}
      </UIPlanBanner.RightSection>
    </UIPlanBanner>
  )
}

export default PlanBanner
