import { push } from 'connected-react-router'
import React from 'react'
import Media from 'react-media'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Plus } from '.'
import Flashy from './flashy'

// eslint-disable-next-line no-shadow
const FloatingNewButton = ({ push }) => (
  <Media
    query="(max-width: 812px) and (max-height: 812px)"
    render={() => (
      <Flashy
        enlarge
        onClick={() => push('/dashboard/new')}
        id="oneclick-button-floating_new"
        style={{
          position: 'fixed',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 100000,
          bottom: '2em',
          right: '2em',
          backgroundColor: 'rgba(100, 171, 255, .4)',
          height: '5em',
          width: '5em',
          borderRadius: '100%'
        }}>
        <div>
          <img src={Plus} alt="New Project" />
        </div>
      </Flashy>
    )}
  />
)

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      push
    },
    dispatch
  )

export default connect(undefined, mapDispatchToProps)(FloatingNewButton)
