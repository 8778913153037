import Cookies from 'js-cookie'
import React from 'react'
import { connect } from 'react-redux'
import { Redirect, Route, useLocation } from 'react-router-dom'

const AuthenticatedRoute = ({ component: Component, loggedIn = false, firstLoginEver = false, ...rest }) => {
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const email = decodeURI(queryParams.get('email'))
  const redirect_to = firstLoginEver ? 'introduce-yourself' : `${location.pathname}/${location.search}` || ''

  if (loggedIn) {
    Cookies.set('loggedIn', { loggedIn: true }, { expires: 30, domain: 'oneclickcode.com' })
    return <Route {...rest} component={Component} />
  }

  Cookies.remove('loggedIn', { domain: 'oneclickcode.com' })
  sessionStorage.setItem('redirect_to', redirect_to)

  return (
    <Route
      {...rest}
      component={props => (
        <Redirect
          to={{
            pathname: '/login',
            state: {
              from: props.location,
              ...(email &&
                email !== 'null' && {
                  email
                })
            }
          }}
        />
      )}
    />
  )
}

const mapStateToProps = ({ auth }) => ({
  loggedIn: auth.loggedIn,
  firstLoginEver: auth.firstLoginEver
})

export default connect(mapStateToProps)(AuthenticatedRoute)
