/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "occapi",
            "endpoint": "https://4m4w7x6kc9.execute-api.us-east-1.amazonaws.com/master",
            "region": "us-east-1"
        }
    ],
    "aws_dynamodb_all_tables_region": "us-east-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "occ-account-hashes-master",
            "region": "us-east-1"
        },
        {
            "tableName": "occ-api-keys-master",
            "region": "us-east-1"
        },
        {
            "tableName": "occ-api-partners-master",
            "region": "us-east-1"
        },
        {
            "tableName": "occ-climate-details-master",
            "region": "us-east-1"
        },
        {
            "tableName": "occ-config-master",
            "region": "us-east-1"
        },
        {
            "tableName": "occ-consumer-teams-master",
            "region": "us-east-1"
        },
        {
            "tableName": "occ-counters-master",
            "region": "us-east-1"
        },
        {
            "tableName": "occ-form-lists-master",
            "region": "us-east-1"
        },
        {
            "tableName": "occ-global-events-master",
            "region": "us-east-1"
        },
        {
            "tableName": "occ-id-account-associations-master",
            "region": "us-east-1"
        },
        {
            "tableName": "occ-iecc-rvalues-master",
            "region": "us-east-1"
        },
        {
            "tableName": "occ-last-user-update-master",
            "region": "us-east-1"
        },
        {
            "tableName": "occ-muni-details-master",
            "region": "us-east-1"
        },
        {
            "tableName": "occ-muni-lookup-errors-master",
            "region": "us-east-1"
        },
        {
            "tableName": "occ-partner-reports-master",
            "region": "us-east-1"
        },
        {
            "tableName": "occ-permit-calc-logs-master",
            "region": "us-east-1"
        },
        {
            "tableName": "occ-products-master",
            "region": "us-east-1"
        },
        {
            "tableName": "occ-profiles-master",
            "region": "us-east-1"
        },
        {
            "tableName": "occ-project-municipal-data-master",
            "region": "us-east-1"
        },
        {
            "tableName": "occ-reports-master",
            "region": "us-east-1"
        },
        {
            "tableName": "occ-sso-domains-master",
            "region": "us-east-1"
        },
        {
            "tableName": "occ-sso-providers-master",
            "region": "us-east-1"
        },
        {
            "tableName": "occ-team-invites-master",
            "region": "us-east-1"
        },
        {
            "tableName": "occ-team-members-master",
            "region": "us-east-1"
        },
        {
            "tableName": "occ-transactions-master",
            "region": "us-east-1"
        },
        {
            "tableName": "occ-user-api-keys-master",
            "region": "us-east-1"
        }
    ]
};


export default awsmobile;
