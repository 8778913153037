import { Box, Button, ButtonText, HStack, Text, View } from '@oneclickdata/components'
import { Icon } from '@oneclickdata/occ-components'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { ROUTES_NAMES } from '../../../constants'
import { useBreakpoint } from '../../../customHooks'

const PurchaseProPlan = ({ displayType, onButtonClick, wrapperStyle }) => {
  const features = ['30 Property Quickviews/mo.', '50 seats', '8 Code Detail add-ons/mo.', '4 Weather History add-ons/mo.', 'Access to Estimating Tools']

  const [isMobileView, setIsMobile] = useState(false)
  const currentView = displayType
  const isTeamPage = currentView === 'MyTeam'
  const history = useHistory()
  const { isMobile } = useBreakpoint()

  useEffect(() => {
    setIsMobile(isMobile)
  }, [isMobile])

  const handlePress = () => {
    onButtonClick && onButtonClick()
    history.push(ROUTES_NAMES.plans.route)
  }

  return (
    <View
      display="flex"
      h="100%"
      w="100%"
      borderRadius="$lg"
      p={isTeamPage ? '$8' : 0}
      pb={isTeamPage && !isMobileView ? '$8' : 0}
      pt={isTeamPage && !isMobileView ? '$8' : 0}
      justifyContent="flex-end"
      backgroundColor="$backgroundBoxes"
      sx={wrapperStyle}>
      <Box alignItems="center" mb="$4" textAlign="center">
        {isMobileView && <Icon name="proplan" size="4xl" />}
        <Text size="h5" bold>
          Unlock{' '}
          <Text size="h5" color="$success400" bold>
            PRO
          </Text>{' '}
          plan to{' '}
        </Text>
        <Text size="h5" color="$amber500" bold>
          {isTeamPage ? 'Invite your team members' : 'Connect to integrations'}
        </Text>
      </Box>
      {features.map((feature, index) => {
        const ix = `feat-${index}`
        return (
          <HStack key={ix} flexDir="row" alignItems="center" mb="$4">
            <Icon name="check" color="secondary.400" size="xs" mr={3} />
            {(feature === 'Integrations' && !isTeamPage) || (feature === '50 seats' && isTeamPage) ? (
              <Text color="$success400" bold>
                {feature}
              </Text>
            ) : (
              <Text>{feature}</Text>
            )}
          </HStack>
        )
      })}
      <Button mb={isTeamPage && isMobileView ? '$4' : 0} mt="$4" action="secondary" onPress={handlePress}>
        <ButtonText>Unlock your PRO plan</ButtonText>
      </Button>
    </View>
  )
}

export default PurchaseProPlan
