import { push, replace } from 'connected-react-router'
import Cookies from 'js-cookie'
import api from '../api'

export const REPORTS_REQUESTED = 'reports/REPORTS_REQUESTED'
export const REPORTS_DELIVERED = 'reports/REPORTS_DELIVERED'
export const REPORTS_DELIVERED_INITIAL = 'reports/REPORTS_DELIVERED_INITIAL'
export const REPORTS_REQUESTED_FAILURE = 'reports/REPORTS_FAIL'

const ADD_REPORT = 'reports/ADD_REPORT'
const ADD_ANONYMOUS_REPORT = 'reports/ADD_ANONYMOUS_REPORT'

const UPDATE_REPORT = 'reports/UPDATE_REPORT'
const GENERATE_REPORT_PDF = 'reports/GENERATE_REPORT_PDF'
const GENERATE_REPORT_PDF_SUCCESS = 'reports/GENERATE_REPORT_PDF_SUCCESS'
const GENERATE_REPORT_PDF_FAILURE = 'reports/GENERATE_REPORT_PDF_FAILURE'

const REMOVE_REPORT_REQUEST = 'reports/REMOVE_REPORT_REQUEST'
const REMOVE_REPORT = 'reports/REMOVE_REPORT'
const REMOVE_REPORT_FAILED = 'reports/REMOVE_REPORT_FAILED'

const TOGGLE_SORT_ORDER = 'reports/TOGGLE_SORT_ORDER'
const TOGGLE_EDIT_MODE = 'reports/TOGGLE_EDIT_MODE'

const ARCHIVE_REPORT = 'reports/ARCHIVE_REPORT'
const UNARCHIVE_REPORT = 'reports/UNARCHIVE_REPORT'

const initialState = {
  requestingReports: false,
  reports: [],
  error: null,
  sortAsc: false,
  editMode: false,
  removingReports: [],
  anonymousReport: null
}

const parseReports = reports => reports.map(parseReport)

const googleMapURL = ({ lat, long }) =>
  `https://maps.googleapis.com/maps/api/staticmap?center=${lat},${long}&zoom=18&markers=color:0x7FCA17%7Csize:small%7C${lat},${long}&size=640x256&maptype=satellite&scale=2&key=AIzaSyDjpJTrNQNeQC-tldSmUcF8X0S2x-PZO_k`

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case REPORTS_REQUESTED:
      return {
        ...state,
        requestingReports: true,
        error: null
      }
    case REPORTS_DELIVERED:
      return {
        ...state,
        requestingReports: false,
        reports: [...state.reports, ...payload]
      }
    case REPORTS_DELIVERED_INITIAL:
      return {
        ...state,
        requestingReports: false,
        reports: payload
      }
    case REPORTS_REQUESTED_FAILURE:
      return {
        ...state,
        makingRequest: false,
        error: payload
      }
    case REMOVE_REPORT_REQUEST:
      return {
        ...state,
        removingReports: [...state.removingReports, payload]
      }
    case REMOVE_REPORT:
      return {
        ...state,
        reports: state.reports.filter(r => r.reportNumber !== payload),
        removingReports: state.removingReports.filter(r => r !== payload)
      }
    case REMOVE_REPORT_FAILED:
      return {
        ...state,
        removingReports: state.removingReports.filter(r => r !== payload)
      }
    case ADD_REPORT:
      return {
        ...state,
        reports: [payload, ...state.reports]
      }
    case GENERATE_REPORT_PDF:
      return {
        ...state,
        generatingReport: true
      }
    case GENERATE_REPORT_PDF_SUCCESS:
      return {
        ...state,
        generatingReport: false
      }
    case GENERATE_REPORT_PDF_FAILURE:
      return {
        ...state,
        generatingReport: false
      }
    case UPDATE_REPORT:
      return {
        ...state,
        reports: [...state.reports.filter(report => report.reportNumber !== payload.reportNumber), payload.updatedReport]
      }
    case ADD_ANONYMOUS_REPORT:
      return {
        ...state,
        anonymousReport: payload
      }
    case TOGGLE_SORT_ORDER:
      return {
        ...state,
        sortAsc: !state.sortAsc
      }
    case TOGGLE_EDIT_MODE:
      return {
        ...state,
        editMode: !state.editMode
      }
    case ARCHIVE_REPORT:
      return {
        ...state,
        reports: [
          ...state.reports.filter(report => report.reportNumber !== payload),
          {
            ...state.reports.find(report => report.reportNumber === payload),
            archived: true
          }
        ]
      }
    case UNARCHIVE_REPORT:
      return {
        ...state,
        reports: [
          ...state.reports.filter(report => report.reportNumber !== payload),
          {
            ...state.reports.find(report => report.reportNumber === payload),
            archived: false
          }
        ]
      }
    default:
      return state
  }
}

export const createReport = (googleResult, returnReport, querySrc, propertyType, roofType, purchaseObject) => async (dispatch) => {
  try {
    const response = await api.createReport(googleResult, querySrc, propertyType, roofType, purchaseObject)
    if (response.report === null) {
      return response
    }
    const report = parseReport(response.report)
    dispatch({
      type: ADD_REPORT,
      payload: report
    })
    if (returnReport) {
      return report
    }
    dispatch(replace(`/report/${report.reportNumber}`))
  } catch (err) {
    throw err.error
  }
}

export const checkForAndAdoptReport = () => async (dispatch, getState) => {
  try {
    const anonymousReport = Cookies.get('anonymousReport')
    const {
      auth: { loggedIn }
    } = getState()
    let report
    if (loggedIn && anonymousReport) {
      const res = await api.adoptReport(anonymousReport)
      // eslint-disable-next-line prefer-destructuring
      report = res.report
      Cookies.remove('anonymousReport')
    }
    dispatch({
      type: ADD_REPORT,
      payload: parseReport(report)
    })
  } catch (err) {
    return false
  }
}

export const addReport = report => dispatch =>
  dispatch({
    type: ADD_REPORT,
    payload: parseReport(report)
  })

export const archiveReport = number => async (dispatch) => {
  try {
    await api.archiveReport(number)
    dispatch({
      type: ARCHIVE_REPORT,
      payload: number
    })
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e)
  }
}

export const unarchiveReport = number => async (dispatch) => {
  try {
    await api.unarchiveReport(number)
    dispatch({
      type: UNARCHIVE_REPORT,
      payload: number
    })
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e)
  }
}

export const generatePDF = reportNumber => async (dispatch, getState) => {
  dispatch({ type: GENERATE_REPORT_PDF })
  try {
    const { url } = await api.getCodeReportPDF(reportNumber)
    const {
      reports: { reports }
    } = getState()
    // eslint-disable-next-line no-shadow
    const report = reports.find(report => report.reportNumber === reportNumber)
    const updatedReport = { ...report, pdfURL: url }
    dispatch({
      type: UPDATE_REPORT,
      payload: { updatedReport, reportNumber }
    })
    dispatch({ type: GENERATE_REPORT_PDF_SUCCESS })

    return url
  } catch (err) {
    dispatch({ type: GENERATE_REPORT_PDF_FAILURE })
  }
}

export const generateManufacturerPDF =
  ({ reportNumber, manufacturer_id, product_id }) =>
  async (dispatch, getState) => {
    dispatch({ type: GENERATE_REPORT_PDF })
    try {
      const {
        reports: { reports }
      } = getState()
      // eslint-disable-next-line no-shadow
      const report = reports.find(report => reportNumber === report.reportNumber)
      const { url } = await api.getManufacturerReportPDF({
        reportNumber,
        manufacturer_id,
        product_id
      })
      const updatedReport = { ...report, manufacturer_report_pdf: url }
      dispatch({
        type: UPDATE_REPORT,
        payload: { updatedReport, reportNumber }
      })
      dispatch({
        type: GENERATE_REPORT_PDF_SUCCESS
      })
    } catch (e) {
      dispatch({ type: GENERATE_REPORT_PDF_FAILURE })
      // eslint-disable-next-line no-console
      console.log({ e })

      return Promise.reject({ e })
    }
  }

export const generateWindHistoryPDF = reportNumber => async (dispatch, getState) => {
  dispatch({ type: GENERATE_REPORT_PDF })
  try {
    const {
      reports: { reports }
    } = getState()
    // eslint-disable-next-line no-shadow
    const report = reports.find(report => reportNumber === report.reportNumber)
    const { url } = await api.getWindHistoryPDF(reportNumber)
    const updatedReport = { ...report, hs_pdf: url }
    dispatch({
      type: UPDATE_REPORT,
      payload: { updatedReport, reportNumber }
    })
    dispatch({
      type: GENERATE_REPORT_PDF_SUCCESS
    })

    return url
  } catch (e) {
    dispatch({ type: GENERATE_REPORT_PDF_FAILURE })
    // eslint-disable-next-line no-console
    console.log({ e })

    return Promise.reject({ e })
  }
}

export const generateHailStormPDF = reportNumber => async (dispatch, getState) => {
  dispatch({ type: GENERATE_REPORT_PDF })
  try {
    const {
      reports: { reports }
    } = getState()
    // eslint-disable-next-line no-shadow
    const report = reports.find(report => reportNumber === report.reportNumber)
    const { url } = await api.getHailStormPDF(reportNumber)
    const updatedReport = { ...report, hs_pdf: url }
    dispatch({
      type: UPDATE_REPORT,
      payload: { updatedReport, reportNumber }
    })
    dispatch({
      type: GENERATE_REPORT_PDF_SUCCESS
    })

    return url
  } catch (e) {
    dispatch({ type: GENERATE_REPORT_PDF_FAILURE })
    // eslint-disable-next-line no-console
    console.log({ e })

    return Promise.reject({ e })
  }
}

export const generateIWSCalculationPDF = reportNumber => async (dispatch, getState) => {
  dispatch({ type: GENERATE_REPORT_PDF })
  try {
    const {
      reports: { reports }
    } = getState()
    // eslint-disable-next-line no-shadow
    const report = reports.find(report => reportNumber === report.reportNumber)
    const { url } = await api.getIWSCalculationPDF(reportNumber)
    const updatedReport = { ...report, iws_calc_pdf: url }
    dispatch({
      type: UPDATE_REPORT,
      payload: { updatedReport, reportNumber }
    })
    dispatch({
      type: GENERATE_REPORT_PDF_SUCCESS
    })

    return url
  } catch (e) {
    dispatch({ type: GENERATE_REPORT_PDF_FAILURE })
    // eslint-disable-next-line no-console
    console.log({ e })

    return Promise.reject({ e })
  }
}

export const generateBuildingDesignCriteriaPDF = reportNumber => async (dispatch, getState) => {
  dispatch({ type: GENERATE_REPORT_PDF })
  try {
    const {
      reports: { reports }
    } = getState()
    // eslint-disable-next-line no-shadow
    const report = reports.find(report => reportNumber === report.reportNumber)
    const { url } = await api.getDesignBuildingCriteriaPDF(reportNumber)
    const updatedReport = { ...report, bd_pdf: url }
    dispatch({
      type: UPDATE_REPORT,
      payload: { updatedReport, reportNumber }
    })
    dispatch({
      type: GENERATE_REPORT_PDF_SUCCESS
    })

    return url
  } catch (e) {
    dispatch({ type: GENERATE_REPORT_PDF_FAILURE })
    // eslint-disable-next-line no-console
    console.log({ e })

    return Promise.reject({ e })
  }
}

export const updateReport = updatedReport => async (dispatch) => {
  dispatch({
    type: UPDATE_REPORT,
    payload: { updatedReport, reportNumber: updatedReport.reportNumber }
  })
}

export const createAnonymousReport = (googleResult, dismiss) => async (dispatch) => {
  try {
    const response = await api.createAnonymousReport(googleResult)
    if (response.data.error) throw response.data.error
    const report = parseReport(response.data.report)
    dispatch({
      type: ADD_ANONYMOUS_REPORT,
      payload: { report, search: googleResult }
    })
    ;(dismiss && dismiss()) || dispatch(push(`/report/${report.reportNumber}?sample=true`))
  } catch (err) {
    alert(err)
  }
}

export const getFollowUpReports = details => async (dispatch) => {
  dispatch({
    type: REPORTS_REQUESTED
  })
  try {
    const { items, cursorId, hasMore } = await api.getReportsV2(details)
    dispatch({
      type: REPORTS_DELIVERED,
      payload: parseReports(items)
    })

    return { cursorId, hasMore }
  } catch (err) {
    dispatch({
      type: REPORTS_REQUESTED_FAILURE,
      payload: err
    })
  }
}

export const getReportsForTeam = details => async () => {
  try {
    const { items, cursorId, hasMore } = await api.getReportsV2(details)

    return { cursorId, hasMore, items }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err)
  }
}

export const getReports = details => async (dispatch, getState) => {
  const state = getState()
  dispatch({
    type: REPORTS_REQUESTED
  })
  try {
    if (state.profile.loadingProfile) {
      await new Promise((resolve) => {
        const interval = setInterval(() => {
          // eslint-disable-next-line no-shadow
          const state = getState()
          if (!state.profile.loadingProfile) {
            resolve()
            clearInterval(interval)
          }
        }, 1)
      })
    }
    const { items, cursorId, hasMore } = await api.getReportsV2(details)
    dispatch({
      type: REPORTS_DELIVERED_INITIAL,
      payload: parseReports(items)
    })

    return { cursorId, hasMore, items }
  } catch (err) {
    dispatch({
      type: REPORTS_REQUESTED_FAILURE,
      payload: err
    })
  }
}

export const removeReport = number => async (dispatch) => {
  dispatch({
    type: REMOVE_REPORT_REQUEST,
    payload: number
  })
  try {
    await api.deleteReport(number)
    dispatch({
      type: REMOVE_REPORT,
      payload: number
    })
  } catch (err) {
    dispatch({
      type: REMOVE_REPORT_FAILED,
      payload: number
    })
  }
}

export const toggleSort = () => ({ type: TOGGLE_SORT_ORDER })

export const toggleEditMode = () => ({ type: TOGGLE_EDIT_MODE })

export const parseReport = report =>
  // eslint-disable-next-line no-underscore-dangle
  report._version >= 2
    ? {
        ...report,
        mapURL: googleMapURL({ lat: report.latitude, long: report.longitude })
      }
    : {
        elevation: report.elevation,
        pdfURL: report.pdfURL,
        addressComponents: JSON.stringify(report.addressComponents),
        street: `${report.addressComponents.street_number} ${report.addressComponents.route}`,
        reportNumber: report.reportNumber,
        reportType: report.reportType,
        controllingMunicipality: report.controllingMuni,
        industry: 'Roofing',
        mapURL: googleMapURL({ lat: report.latitude, long: report.longitude }),
        irc: report.irc.replace(' IRC', ''),
        ibc: report.ibc.replace(' IBC', ''),
        iecc: report.iecc.replace(' IECC', ''),
        permitRequired: report.roofingPermitsRequired === 'Yes',
        codeEnforced: report.codeEnforced === 'Yes',
        iceAndWatershield: report.iwsRequired === 'Yes',
        dripEdgeRakes: report.dripEdgeRakes === 'Yes',
        dripEdgeEaves: report.dripEdgeEaves === 'Yes',
        threeSecondGusts: report.threeSecondGusts || 0,
        sustainedSpeed: report.sustainedSpeed || 0,
        ultimateDesign: report.ultimateDesign || 0,
        installPerManufacturer: report.shinglesPerManufacturer === 'Yes',
        controllingMunicipalityContact: {
          website: report.muni_url,
          phone: report.muni_phone,
          email: report.muni_email
        },
        buildingOfficialContact: {
          name: report.cboName ? report.cboName.toUpperCase() : report.cboName,
          phone: report.cboPhone,
          email: report.cboEmail
        }
      }
