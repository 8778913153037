/* eslint-disable max-classes-per-file */
import React from 'react'
import ReactDOM from 'react-dom'
import cbElHolder from './cbEl'

class ControlBar extends React.Component {
  render() {
    return <div ref={div => (cbElHolder.el = div)} />
  }
}

export default ControlBar

class ControlBarPortal extends React.Component {
  holder = cbElHolder

  constructor(props) {
    super(props)
    this.el = document.createElement('div')
    this.el.style.padding = '0px'
    this.el.style.margin = '0px'
  }

  componentDidMount() {
    while (cbElHolder.el && cbElHolder.el.lastChild) {
      cbElHolder.el.removeChild(cbElHolder.el.lastChild)
    }
    cbElHolder.el.appendChild(this.el)
  }

  componentWillUnmount() {}

  render() {
    const { children } = this.props
    return ReactDOM.createPortal(children, this.el)
  }
}

export { ControlBarPortal }
