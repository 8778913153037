import React from 'react'
import MainLayout from '../../layouts'

const withLayout = Component => (props) => {
  const { hideLayout } = props

  return hideLayout ? (
    <Component {...props} />
  ) : (
    <MainLayout>
      <Component {...props} />
    </MainLayout>
  )
}

export default withLayout
