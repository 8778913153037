import { getStableUUID } from '../../customHooks/useStableUUID'
import { env } from '../../envTest'
import { firebaseAnalytics } from './firebaseConfig'

// Firebase Handler
export const firebaseHandler = {
  name: 'Firebase',
  logEvent: (name, params, options) => firebaseAnalytics.logEvent(name, params, options),
  identify: (id, properties) => {
    firebaseAnalytics.setUserId(id)
    firebaseHandler.setProperties(properties)
  },
  setProperties: ({ profileProperties, groupProperties } = {}, options) => firebaseAnalytics.setUserProperties({ ...profileProperties, ...groupProperties }, options),
  reset: () => firebaseAnalytics.setUserId(null)
}

// Segment-like Analytics Handler
export const segmentHandler = {
  id: null,
  name: 'Segment',
  logEvent: (name, params, options) => window.analytics.track(name, params, options),
  identify: (id, properties) => {
    segmentHandler.id = id
    segmentHandler.setProperties(properties)
  },
  setProperties: ({ profileProperties, groupProperties } = {}, options) => window.analytics.identify(segmentHandler.id, { ...groupProperties, ...profileProperties }, options),
  reset: () => {
    segmentHandler.id = null
    window.analytics.reset()
  }
}

export const consoleHandler = {
  id: null,
  name: 'Console',
  isActive: localStorage.getItem('showUserDebug') === 'true',
  logEvent: async (name, params, options) => {
    if (!consoleHandler.id) {
      const stableUUID = getStableUUID()
      consoleHandler.identify(stableUUID)
    }
    consoleHandler.log(name, params, options)
  },
  identify: (id, properties) => {
    consoleHandler.id = id
    consoleHandler.setProperties(properties)
  },
  setProperties: ({ profileProperties, groupProperties } = {}, options) => {
    if (!consoleHandler.id) {
      const stableUUID = getStableUUID()
      consoleHandler.identify(stableUUID)
    }
    consoleHandler.log(consoleHandler.id, { ...groupProperties, ...profileProperties }, options)
  },
  reset: () => {
    consoleHandler.id = null
    consoleHandler.log('analytics reset')
  },
  log: (...args) => {
    if (consoleHandler.isActive) {
      // eslint-disable-next-line no-console
      console.info(...args)
    }
  }
}

// Pendo Handler
export const pendoHandler = {
  isInitialized: false,
  name: 'Pendo',
  logEvent: async (name, params) => {
    if (!pendoHandler.isInitialized) {
      const stableUUID = getStableUUID()
      pendoHandler.identify(stableUUID)
    }
    window.pendo.track(name, params)
  },
  identify: (id, { profileProperties, groupProperties } = {}) => {
    let idMethod = window.pendo.initialize
    if (pendoHandler.isInitialized) {
      idMethod = window.pendo.updateOptions
    } else {
      pendoHandler.isInitialized = true
    }
    idMethod({
      visitor: { ...profileProperties, id, env },
      account: {
        id,
        ...groupProperties,
        ...(groupProperties?.team_id && { id: groupProperties.team_id }),
        env
      }
    })
  },
  setProperties: ({ profileProperties, groupProperties } = {}) => {
    if (!pendoHandler.isInitialized) {
      const stableUUID = getStableUUID()
      pendoHandler.identify(stableUUID)
    }
    window.pendo.updateOptions({ visitor: { ...profileProperties }, account: { ...groupProperties } })
  },
  reset: () => window.pendo.clearSession()
}
