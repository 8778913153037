import React from 'react'
import ReactDOM from 'react-dom'
import doubleCB from './doubleCB'

class DoubleControlBar extends React.Component {
  render() {
    return <div ref={div => (doubleCB.el = div)} style={{ paddingRight: 0, paddingLeft: 0 }} />
  }
}

class DoubleControlBarPortal extends React.Component {
  constructor(props) {
    super(props)
    this.el = document.createElement('div')
  }

  holder = doubleCB

  componentDidMount() {
    while (doubleCB.el && doubleCB.el.lastChild) {
      doubleCB.el.removeChild(doubleCB.el.lastChild)
    }

    doubleCB.el.appendChild(this.el)
  }

  componentWillUnmount() {}

  render() {
    // eslint-disable-next-line react/destructuring-assignment
    return ReactDOM.createPortal(this.props.children, this.el)
  }
}

export default DoubleControlBar

export { DoubleControlBarPortal }
