import { Box } from '@oneclickdata/components'
import React from 'react'
import { connect, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import { AlertBoxOutput, AlertBoxProvider } from '../components/AlertBox'
import ControlBar from '../components/ControlBar'
import DashboardHeader from '../components/DashboardHeader'
import DoubleControlBar from '../components/DoubleControlBar'
import Footer from '../components/Footer'
import { PageContainer } from '../components/PageContainer'
import { MobileHeaderProvider } from '../contexts/MobileHeaderContext'
import useMobileHeader from '../customHooks/useMobileHeader'
import { logout } from '../modules/auth'

const storeSelector = ({ auth, router }) => ({
  router,
  loggedIn: auth.loggedIn
})

const Layout = (props) => {
  // eslint-disable-next-line no-shadow
  const { children, email, groups, logout, name } = props

  const { loggedIn } = useSelector(storeSelector)
  const { hideDefaultHeader } = useMobileHeader()

  return (
    <Box flex={1}>
      {!hideDefaultHeader && (
        <Box zIndex="100">
          <DashboardHeader email={email} groups={groups} logout={logout} name={name} />
        </Box>
      )}

      <AlertBoxOutput zIndex="50" top="0" nativeID="alert_box__container" />

      <PageContainer nativeID="control_bar__container">
        <ControlBar />
        <DoubleControlBar />
      </PageContainer>

      <PageContainer zIndex={2} flex={1}>
        <Box zIndex={2} flex={1}>
          {children}
        </Box>
        {loggedIn && (
          <Box zIndex={1}>
            <Footer />
          </Box>
        )}
      </PageContainer>
    </Box>
  )
}

const MainLayout = (props) => {
  return (
    <MobileHeaderProvider>
      <AlertBoxProvider>
        <Layout {...props} />
      </AlertBoxProvider>
    </MobileHeaderProvider>
  )
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      logout
    },
    dispatch
  )

const mapStateToProps = ({ auth, profile, router }) => ({
  loadingProfile: profile.loadingProfile,
  loggedIn: auth.loggedIn,
  profession: profile.professionType,
  name: profile.givenName,
  email: auth.email,
  groups: auth.groups,
  location: router.location.pathname
})

export default connect(mapStateToProps, mapDispatchToProps)(MainLayout)
