import { Auth } from 'aws-amplify'
import { login } from './auth'

export const MAKE_NETWORK_REQUEST = 'forgot/MAKE_NETWORK_REQUEST'
export const NETWORK_REQUEST_ERROR = 'forgot/NETWORK_REQUEST_ERROR'
export const RESET_CODE_DELIVERED = 'forgot/RESET_CODE_DELIVERED'
export const NEW_PASSWORD_SUCCESS = 'forgot/NEW_PASSWORD_SUCCESS'
export const RESET_REDUCER_TO_DEFAULT = 'forgot/RESET_REDUCER_TO_DEFAULT'

const initialState = {
  makingRequest: false,
  showReset: false,
  resetDestination: null,
  error: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case MAKE_NETWORK_REQUEST:
      return {
        ...state,
        makingRequest: true,
        error: null
      }
    case NETWORK_REQUEST_ERROR:
      return {
        ...state,
        makingRequest: false,
        error: action.payload
      }
    case RESET_CODE_DELIVERED:
      return {
        ...state,
        makingRequest: false,
        showReset: true,
        error: null,
        resetDestination: action.payload
      }
    case NEW_PASSWORD_SUCCESS:
      return {
        ...state,
        makingRequest: false,
        showReset: false,
        error: null
      }
    case RESET_REDUCER_TO_DEFAULT:
      return {
        ...state,
        makingRequest: false,
        showReset: false,
        resetDestination: null,
        error: null
      }
    default:
      return state
  }
}

export const resetReducerToDefault = () => (dispatch) => {
  dispatch({
    type: RESET_REDUCER_TO_DEFAULT
  })
}

export const requestCode = username => async (dispatch) => {
  dispatch({
    type: MAKE_NETWORK_REQUEST
  })
  try {
    const { CodeDeliveryDetails } = await Auth.forgotPassword(username)
    dispatch({
      type: RESET_CODE_DELIVERED,
      payload: CodeDeliveryDetails.Destination
    })
  } catch (err) {
    dispatch(requestFailedWith(err))
    throw err
  }
}

export const submitNewPasswordAndLoginTo = (username, resetCode, newPassword, to) => async (dispatch) => {
  dispatch({
    type: MAKE_NETWORK_REQUEST
  })
  try {
    await Auth.forgotPasswordSubmit(username, resetCode, newPassword)
    dispatch({
      type: NEW_PASSWORD_SUCCESS
    })
    dispatch(
      login(username, newPassword, {
        redirectTo: to
      })
    )
  } catch (err) {
    dispatch(requestFailedWith(err))
  }
}

const requestFailedWith = (error) => {
  const errorMessage = error.message

  return dispatch =>
    dispatch({
      type: NETWORK_REQUEST_ERROR,
      payload: errorMessage
    })
}
