import { push } from 'connected-react-router'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Flashy from './flashy'

// eslint-disable-next-line no-shadow
const Blink = ({ to, push, onClick, whenFollowed, ...rest }) => <Flashy onClick={to ? () => push(to) : onClick} {...rest} whenFollowed={whenFollowed} />

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      push
    },
    dispatch
  )

export default connect(undefined, mapDispatchToProps)(Blink)
