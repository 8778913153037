import { API, Auth } from 'aws-amplify'
import axios from 'axios'

const BASE_PATH = '/v2/calculators'
const manager = {
  saveCalculation: async (details) => {
    await Auth.currentCredentials()
    const apiResponse = await API.post('occapi', `${BASE_PATH}/calculate`, {
      body: { ...details }
    })

    return apiResponse
  },

  getWasteEstimates: async (body) => {
    const ENDPOINT = 'https://api.oneclickcode.com/v2.dev/public/calculators/waste_estimates'
    const { data } = await axios.post(ENDPOINT, body)

    return data
  }
}

export default manager
