/* eslint-disable no-underscore-dangle, no-undef */
import { routerMiddleware } from 'connected-react-router'
import { createBrowserHistory as createHistory } from 'history'
import { applyMiddleware, compose, createStore } from 'redux'
import thunk from 'redux-thunk'
import createRootReducer from '../modules'
import '../services/analytics'

export const history = createHistory()

const initialState = {}
const enhancers = []

const analytics = () => next => (action) => {
  next(action)
}

const middleware = [thunk, routerMiddleware(history), analytics]

if (process.env.NODE_ENV === 'development') {
  const devToolsExtension = window.window.__REDUX_DEVTOOLS_EXTENSION__

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension())
  }
}

const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers)

const store = createStore(createRootReducer(history), initialState, composedEnhancers)

if (module.hot) {
  module.hot.accept('../modules', () => {
    store.replaceReducer(createRootReducer(history))
  })
}

export default store
