/* eslint-disable no-underscore-dangle, no-shadow, react-hooks/exhaustive-deps, no-param-reassign, no-console */
import { PublicClientApplication } from '@azure/msal-browser'
import { MsalProvider } from '@azure/msal-react'
import { GluestackUIProvider, ModalContextProvider, useShowModal, useToast } from '@oneclickdata/components'
import { config } from '@oneclickdata/config'
import { darkTheme } from '@oneclickdata/occ-components'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { Auth } from 'aws-amplify'
import jwt_decode from 'jwt-decode'
import { NativeBaseProvider } from 'native-base'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import DocumentTitle from 'react-document-title'
import { connect } from 'react-redux'
import { useHistory, withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { StatsigProvider } from 'statsig-react'
import API from './api'
import EnvTag from './components/EnvTag'
import { msalConfig } from './components/MicrosoftButton/authConfig'
import { DashboardProvider } from './contexts/Dashboard.context'
import DialogProvider from './contexts/Dialog.context'
import './css/components.css'
import './css/webflow.css'
import { env, isProd } from './envTest'
import { loginCurrentUser, setFederatedInfo, setFederatedTimeout } from './modules/auth'
import { loadProfile } from './modules/profile'
import Loading from './native/components/Loading'
import TeamInvitesOverlay from './native/components/TeamInvitesOverlay'
import Router from './routers'
import analytics from './services/analytics'
import { ErrorTrackingBoundary } from './services/errorTracking'
import etag from './utils/etag'
import useSearchSessionStorage from './utils/useSearchSessionStorage'
import useURLEnv from './utils/useURLEnv'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK)

const params = {
  clientId: process.env.REACT_APP_APPLE_SIGNIN_CLIENT_ID,
  redirectURI: process.env.REACT_APP_APPLE_SIGNIN_REDIRECT,
  scope: 'name email',
  nonce: Date.now().toString(),
  usePopup: true
}

window.AppleID.auth.init(params)

const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID

const handleSkipGoogleLogin = (notification) => {
  if (!notification || notification.isNotDisplayed() || notification.isSkippedMoment()) {
    // TODO: is there a better way of refreshing here with out just calling replace?
    Auth.signOut()
    localStorage.clear()
    window.location.replace('/login')
  }
}

if (!isProd) window.handleSkipGoogleLogin = handleSkipGoogleLogin

const refreshGoogleToken = () => window.google.accounts.id.prompt(handleSkipGoogleLogin)

Auth.configure({ refreshHandlers: { google: refreshGoogleToken } })

const initGoogle = async ({ _loginCurrentUser, timeoutRef, showModalRef }) => {
  window.google.accounts.id.initialize({
    itp_support: true,
    client_id: GOOGLE_CLIENT_ID,
    callback: (response) => {
      getAWSCredentials({
        response,
        _loginCurrentUser,
        loginType: 'google',
        timeoutRef,
        showModalRef
      })
    }
  })
}

const getAWSCredentials = async ({ response, _loginCurrentUser, loginType = 'google', timeoutRef, showModalRef } = {}) => {
  const decoded = jwt_decode(response.credential)

  const user = {
    email: decoded.email,
    name: decoded.name,
    source: 'federated'
  }

  const credentials = await Auth.federatedSignIn(loginType, { token: response.credential, expires_at: decoded.exp }, user)

  _loginCurrentUser(`${loginType}-account`, {
    email: decoded.email,
    givenName: decoded.given_name
  })
  checkCreds({ timeoutRef, showModalRef })

  return credentials
}

const DisplayAppToast = ({ profile }) => {
  const toast = useToast()
  const history = useHistory()

  const noticeIds = profile.notices ? profile.notices.map(notice => notice.id).filter(n => n) : []

  const noticeHash = noticeIds.join('')

  const migrationCheckRef = useRef(null)

  const { search } = history.location
  const urlParams = new URLSearchParams(search)
  const hidePastDueToast = Boolean(urlParams.get('hidePastDueToast'))

  useEffect(() => {
    if (noticeHash !== '') {
      profile.notices.forEach((notice) => {
        const { id, message, webAction, trackingData, type } = notice

        if (trackingData) {
          const { eventName, ...rest } = trackingData
          analytics.logEvent(eventName, { ...rest })
        }

        if (id === 'migration_notice' && migrationCheckRef.current === null) {
          migrationCheckRef.current = setInterval(() => {
            loadProfile()
          }, 3000)
        }

        if (id === 'past_due_notice' && hidePastDueToast !== true) {
          toast.show({ message, action: type })
        } else if (id !== 'past_due_notice') {
          const toastOptions = {
            message,
            status: type,
            placement: 'bottom-left'
          }
          if (webAction) {
            toastOptions.onPress = () => history.push(webAction)
            toastOptions.buttonLabel = 'Take Action'
          }
          toast.show(toastOptions)
        }
      })
    } else {
      toast.closeAll()

      if (migrationCheckRef.current) {
        clearInterval(migrationCheckRef.current)

        toast.show({
          status: 'success',
          message: 'All projects available.',
          title: 'Account Updated',
          placement: 'bottom-left'
        })
      }
    }
  }, [noticeHash])
  return <></>
}

const pca = new PublicClientApplication(msalConfig)

const checkCreds = async ({ timeoutRef, showModalRef }) => {
  const federatedStorageInfo = localStorage.getItem('aws-amplify-federatedInfo')
  const parsedFI = JSON.parse(federatedStorageInfo)

  if (parsedFI?.provider === 'google') {
    const timeoutLength = parsedFI.expires_at - Date.now()
    if (timeoutRef.current || window._google_timeout) {
      clearTimeout(timeoutRef.current || window._google_timeout)
      timeoutRef.current = null
    }

    try {
      await Auth.currentUserCredentials()
      timeoutRef.current = setTimeout(() => checkCreds({ timeoutRef, showModalRef }), timeoutLength)
      window._google_timeout = timeoutRef.current
    } catch (e) {
      await showModalRef.current({ invisible: true, dismissible: false })
    }
  }
}

const App = ({ loginCurrentUser, loadProfile, userEmail, profile, isLoggingIn }) => {
  useEffect(() => {
    etag.cacheCurrentValue()
  }, [])

  const history = useHistory()
  const showModalRef = useRef(null)
  const [isLoading, setIsLoading] = useState(true)
  const [invite, setInvite] = useState(null)
  const [showTeamInviteModal, setTeamInviteModal] = useState(false)
  const [isHandlingInvite, setIsHandlingInvite] = useState(false)
  const [isEmailVerified, setIsEmailVerified] = useState(true)

  useSearchSessionStorage()
  useURLEnv()

  // attempt google login loginCurrentUser
  const timeoutRef = useRef(null)

  useEffect(() => {
    const checkForCurrentUser = async () => {
      try {
        initGoogle({
          _loginCurrentUser: loginCurrentUser,
          timeoutRef,
          showModalRef
        })
        const response = await loginCurrentUser()
        if (typeof response === 'undefined') {
          setIsLoading(false)
        }
        await checkCreds({ timeoutRef, showModalRef })
      } catch (e) {
        setIsLoading(false)
      }
    }

    checkForCurrentUser()
  }, [])

  const { intitial_signup_email_verified, userId, email, team, next_login_route } = profile ?? {}
  const { sso_handle } = team ?? {}
  const readyForInviteCheck = !isLoading && !!email

  useEffect(() => {
    const checkInvites = async () => {
      const inviteResponse = await API.teams.checkTeamInvite(email)
      if (inviteResponse.teamName && (userId || email) && !sso_handle) {
        const userStatus = inviteResponse.teamMembers?.find(x => x.id === email)?.status
        if (userStatus === 'pending' && !inviteResponse.ssoHandle) {
          setInvite(inviteResponse)
          setTeamInviteModal(true)
        }
      }
    }
    const checkEmailStatus = async () => {
      if (!intitial_signup_email_verified) {
        setIsEmailVerified(false)
      }
    }
    if (readyForInviteCheck) {
      checkEmailStatus()
      checkInvites()
    }
  }, [email, intitial_signup_email_verified, sso_handle, userId, readyForInviteCheck])

  const { loadingProfile, doesNotExist } = profile

  useEffect(() => {
    if (!loadingProfile && !doesNotExist) {
      setIsLoading(false)
    }
  }, [isLoading, loadingProfile, doesNotExist])

  const handleTeamInviteClick = async (accepted) => {
    setIsHandlingInvite(true)
    try {
      await API.teams.handleTeamInviteDecision(invite?.team_id, {
        deleted: false,
        accepted,
        email: userEmail,
        invite
      })
      loadProfile()
      setTeamInviteModal(false)
    } catch (e) {
      console.log(e)
    } finally {
      setIsHandlingInvite(false)
    }
  }

  const planSku = profile?.subscriptions?.[0]?.sku
  const statisgUser = useMemo(
    () => ({
      userID: userId,
      custom: {
        planSku
      }
    }),
    [userId, planSku]
  )

  useEffect(() => {
    if (next_login_route && !(isLoading || isLoggingIn)) {
      API.profile.save({ next_login_route: false })
      history.push(next_login_route)
    }
  }, [history, next_login_route, isLoading, isLoggingIn])

  if (isLoading || isLoggingIn) {
    return (
      <>
        <EnvTag env={env} />
        <Loading />
      </>
    )
  }

  return (
    <>
      <EnvTag env={env} />
      <MsalProvider instance={pca}>
        {showTeamInviteModal && isEmailVerified && (
          <TeamInvitesOverlay
            visible
            isHandlingInvite={isHandlingInvite}
            onClick={handleTeamInviteClick}
            teamName={invite?.teamName}
            adminName={invite?.adminName || invite?.teamName}
            teamLogo={invite?.teamLogo}
            onClose={() => {
              setTeamInviteModal(false)
              setInvite(null)
            }}
          />
        )}
        <DocumentTitle title="OneClickCode" />
        <StatsigProvider
          waitForInitialization
          mountKey={userId}
          sdkKey="client-GVoXMt2FjDbcggP6faLmmGzB3FG3C4mIxWAAagze9qr"
          user={statisgUser}
          options={{
            environment: { tier: process.env.REACT_APP_ENV }
          }}>
          <Elements
            stripe={stripePromise}
            options={{
              fonts: [
                {
                  cssSrc: 'https://fonts.googleapis.com/css2?family=Barlow:wght@400'
                }
              ]
            }}>
            <NativeBaseProvider theme={darkTheme} colorModeManager={{ get: () => 'dark' }}>
              <GluestackUIProvider config={config} colorMode="dark">
                <ModalContextProvider>
                  <DialogProvider>
                    <ErrorTrackingBoundary>
                      <DashboardProvider>
                        <Router />
                        <DisplayAppToast profile={profile} />
                        <ModalSetup showModalRef={showModalRef} />
                      </DashboardProvider>
                    </ErrorTrackingBoundary>
                  </DialogProvider>
                </ModalContextProvider>
              </GluestackUIProvider>
            </NativeBaseProvider>
          </Elements>
        </StatsigProvider>
      </MsalProvider>
    </>
  )
}

const mapStateToProps = state => ({
  userEmail: state.profile.email || state.auth.email,
  profile: state.profile,
  isLoggingIn: state.auth.isLoggingIn
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      loginCurrentUser,
      setFederatedInfo,
      setFederatedTimeout,
      loadProfile
    },
    dispatch
  )

const ModalSetup = ({ showModalRef }) => {
  const showModal = useShowModal()
  useEffect(() => {
    if (!showModalRef.current) {
      showModalRef.current = showModal
    }
  }, [])

  return null
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App))
