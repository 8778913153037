import React from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Route } from 'react-router-dom'

const RedirectedComponent = () => {
  const { firstLoginEver, loginState } = useSelector(({ auth }) => ({
    firstLoginEver: auth.firstLoginEver,
    loginState: auth.loginState
  }))

  const redirect_to = firstLoginEver ? '/introduce-yourself' : sessionStorage.getItem('redirect_to') || '/dashboard'

  return <Redirect to={{ pathname: redirect_to, state: loginState }} />
}

const NonAuthenticatedRoute = ({ component: Component, ...rest }) => {
  const loggedIn = useSelector(({ auth }) => auth.loggedIn)

  return <Route {...rest} component={loggedIn ? RedirectedComponent : Component} />
}

export default NonAuthenticatedRoute
