import { useShowModal } from '@oneclickdata/components'
import { useHistory } from 'react-router-dom'
import analytics from '../services/analytics'

export const useOpenPDF = () => {
  const showModal = useShowModal()
  const history = useHistory()

  const handleOpenBlankURL = async ({ type, projectNumber }) => {
    analytics.logEvent('appError', {
      type,
      projectNumber,
      errorType: 'missingPdfUrl'
    })

    const response = await showModal({
      dismissible: false,
      title: 'Access Issue',
      description: 'Something went wrong trying to open the requested report. Please contact us for help.',
      secondaryActionText: 'Close',
      primaryActionText: 'Contact Us'
    })

    if (response.result === 'primary') {
      history.push(`/feedback?source=codeReport&reportNumber=${projectNumber}`)
    }

    return response
  }

  const handleBrowserBlocked = async (...args) => {
    const response = await showModal({
      dismissible: false,
      title: 'View Report',
      description: 'Your browser blocked the report from opening.',
      secondaryActionText: 'Cancel',
      primaryActionText: 'Open Report'
    })

    if (response.result === 'primary') {
      return openPDF(...args)
    }

    return response
  }

  /**
   * Opens a PDF in a new browser tab or window.
   *
   * @param {string} url - The URL of the PDF to be opened.
   * @param {Object} analyticsParams - Optional parameters for analytics tracking.
   * @param {string} analyticsParams.type - The type of report or document being accessed.
   * @param {string} analyticsParams.reportNumber - The unique number or identifier for the report.
   */
  const openPDF = async (url, analyticsParams = {}) => {
    if (!url) {
      return handleOpenBlankURL({
        type: analyticsParams.type,
        projectNumber: analyticsParams.reportNumber
      })
    }
    const newWindow = window.open(url, '_blank')
    if (!newWindow) {
      return handleBrowserBlocked(url, analyticsParams)
    }
    analytics.logEvent('lastUserAccess', {
      lastAccess: Date.now(),
      type: analyticsParams.type,
      reportNumber: analyticsParams.reportNumber
    })
  }

  return openPDF
}
