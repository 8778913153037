import { BaseModal } from '@oneclickdata/components'
import React, { useState } from 'react'
import API from '../../../api'

const eventTriggerId = {
  upgradeToStarter: async ({ push, reportObject }, closeModal) => {
    try {
      const { value: freeProduct } = await API.config.get('defaultFreeProduct')
      const result = await API.updateUserSubcription(freeProduct)
      if (result) {
        closeModal()
        push({
          pathname: 'singlePurchase',
          state: {
            ...reportObject
          }
        })
      }
    } catch (e) {
      closeModal()
      // eslint-disable-next-line no-console
      console.error(e)
    }
  },
  purchaseOnDemand: ({ push, reportObject }, closeModal) => {
    closeModal()
    push({
      pathname: 'singlePurchase',
      state: {
        ...reportObject
      }
    })
  },
  purchaseProPlan: ({ push }, closeModal) => {
    closeModal()
    push(`/plans/purchase?sku=plan_23_q2_0003&payUpFront=false`, {
      selectedPlan: {
        sku: 'plan_23_q2_0003',
        type: 'paid'
      }
    })
  }
}
const NewModal = (props) => {
  const [modalOpen, setModalOpen] = useState(true)
  const { title, message, style, dismiss = () => null } = props

  const closeModal = () => {
    setModalOpen(false)
    dismiss()
  }

  const { buttons } = style || {}
  const { button1Style, button2Style } = buttons || {}

  return (
    <BaseModal
      isOpen={modalOpen}
      title={title}
      description={message}
      onClose={() => closeModal()}
      primaryActionText={button1Style?.text}
      secondaryActionText={button2Style?.text}
      primaryActionProps={{
        style: button1Style?.buttonStyle,
        ...(button1Style && button1Style)
      }}
      secondaryActionProps={{
        style: button2Style?.buttonStyle,
        ...(button2Style && button2Style)
      }}
      primaryAction={() => {
        if (button1Style?.webRedirect) {
          props.push({
            pathname: button1Style.webRedirect
          })
        } else if (button1Style?.action) {
          button1Style.action()
        } else {
          const eventName = `${button1Style.eventTriggerId}`
          if (eventTriggerId[eventName]) eventTriggerId[eventName](props, closeModal)
        }
        closeModal()
      }}
      secondaryAction={() => {
        if (button2Style?.webRedirect) {
          props.push({
            pathname: button2Style.webRedirect
          })
        } else {
          const eventName = `${button2Style.eventTriggerId}`
          eventTriggerId[eventName](props, closeModal)
        }
        closeModal()
      }}
    />
  )
}

export default NewModal
