import { Honeybadger, HoneybadgerErrorBoundary } from '@honeybadger-io/react'
import { AnalyticsProvider } from '@oneclickdata/components'
import { focusManager, QueryClientProvider } from '@tanstack/react-query'
import Amplify, { Auth } from 'aws-amplify'
import { ConnectedRouter as Router } from 'connected-react-router'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { useHistory } from 'react-router-dom'
import 'source-map-support/register'
import Datasets from './api/AWSIdentityDatasets'
import App from './App'
import aws_config from './aws-config'
import { QueryDevTools } from './components/QueryDevTools'
import './index.css'
import './layoutAndFont.css'
import { queryClient } from './queryClient'
import analytics from './services/analytics'
import store, { history } from './store'

focusManager.setEventListener((handleFocus) => {
  // Listen to visibilitychange and focus
  if (typeof window !== 'undefined' && window.addEventListener) {
    window.addEventListener('visibilitychange', handleFocus, false)
  }

  return () => {
    // Be sure to unsubscribe if a new handler is set
    window.removeEventListener('visibilitychange', handleFocus)
  }
})

Amplify.configure(aws_config)
Datasets.configure({ IdentityPoolId: aws_config.aws_cognito_identity_pool_id })

const reportData = process.env.REACT_APP_HONEY_BADGER_DISABLE_REPORT_DATA === false

export const honeyBadger = Honeybadger.configure({
  apiKey: process.env.REACT_APP_HONEY_BADGER_KEY,
  revision: process.env.HONEYBADGET_REVISION,
  developmentEnvironments: ['DEV'],
  environment: process.env.REACT_APP_ENV || 'master',
  // https://docs.honeybadger.io/lib/javascript/guides/environments-and-versions.html#development-environments
  reportData
})

/**
 * Retrieves user access information from the current session.
 *
 * @async
 * @function getUserAccessInfo
 * @returns {Promise<Object>} An object containing user access information.
 * @property {boolean} accessDenied - Indicates if access is denied.
 * @property {string|null} email - The user's email address.
 * @property {string|null} existingAccountType - The type of the existing account.
 * @property {string|null} identityProviderId - The id of the identity provider.
 */
const getUserAccessInfo = async () => {
  try {
    const session = await Auth.currentSession()
    const idToken = session.getIdToken().decodePayload()

    return {
      accessDenied: idToken['custom:accessDenied'] === 'true',
      email: idToken.email,
      existingAccountType: idToken['custom:existingAccountType'],
      identityProviderId: idToken['custom:identityProviderId']
    }
  } catch (e) {
    return {
      accessDenied: false,
      email: null,
      existingAccountType: null,
      identityProviderId: null
    }
  }
}

const clearCognitoTokens = () => {
  const lastAuthUserKey = 'CognitoIdentityServiceProvider.'
  const idTokenKey = '.idToken'
  const accessTokenKey = '.accessToken'
  const refreshTokenKey = '.refreshToken'

  // Iterate over local storage keys and remove the relevant items
  Object.keys(localStorage).forEach((key) => {
    if (key.startsWith(lastAuthUserKey) || key.endsWith(idTokenKey) || key.endsWith(accessTokenKey) || key.endsWith(refreshTokenKey)) {
      localStorage.removeItem(key)
    }
  })
}

function SAMLProtector({ children }) {
  const [isAccessDenied, setIsAccessDenied] = React.useState(true)
  // eslint-disable-next-line no-shadow
  const history = useHistory()

  React.useEffect(() => {
    const fetchUserAccessInfo = async () => {
      try {
        const { accessDenied, email, existingAccountType, identityProviderId } = await getUserAccessInfo()
        // eslint-disable-next-line no-console
        console.log({ accessDenied, email, existingAccountType })

        if (accessDenied) {
          clearCognitoTokens()
          if (existingAccountType === 'cognito-account') {
            history.push('/login', {
              email,
              returningUser: true
            })
          } else {
            history.push('/welcome-back', {
              email,
              type: existingAccountType,
              identityProviderId
            })
          }

          setIsAccessDenied(false)
        } else {
          setIsAccessDenied(accessDenied)
        }
      } catch (e) {
        setIsAccessDenied(false)
        // eslint-disable-next-line no-console
        console.log(e)
      }
    }

    fetchUserAccessInfo()
  }, [history])

  return isAccessDenied ? null : children
}

ReactDOM.render(
  <HoneybadgerErrorBoundary honeybadger={honeyBadger}>
    <AnalyticsProvider value={analytics}>
      <Provider store={store}>
        <Router history={history}>
          <QueryClientProvider client={queryClient}>
            <QueryDevTools />
            <SAMLProtector>
              <App />
            </SAMLProtector>
          </QueryClientProvider>
        </Router>
      </Provider>
    </AnalyticsProvider>
  </HoneybadgerErrorBoundary>,
  document.getElementById('root')
)

if (module.hot) {
  module.hot.accept()
}

if (window.Cypress) {
  window.store = store
}
