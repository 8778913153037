import React, { useEffect } from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { connect, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import Avatar from '../../../components/Avatar'
import Buttons from '../../../components/Buttons'
import FormButton from '../../../components/FormButton'
import Modal from '../../../components/Modal'
import { ROUTES_NAMES } from '../../../constants'
import photo from '../../../images/icons/teams/photo.svg'
import analytics from '../../../services/analytics'
import { brand } from '../../../utils/CommonStyles'
import useResponsive from '../../../utils/useRepsonsive'
import Spinner from '../Spinner'

const styles = StyleSheet.create({
  modalContent: {
    padding: 25
  },
  modalTitle: {
    fontSize: 30,
    fontFamily: 'Barlow',
    fontWeight: '700',
    color: '#fff',
    textAlign: 'center',
    maxWidth: 420,
    marginBottom: 20,
    marginTop: 20,
    lineHeight: '42px'
  },
  description: {
    fontFamily: 'Barlow',
    fontWeight: '400',
    fontSize: 18,
    color: '#fff',
    textAlign: 'center',
    maxWidth: 420,
    marginBottom: 20,
    lineHeight: '20px'
  },
  remainingText: {
    fontFamily: 'Barlow',
    fontWeight: '400',
    fontSize: 24,
    color: '#fff',
    textAlign: 'center',
    marginBottom: 25
  },
  remainingBoldText: {
    fontFamily: 'Barlow',
    fontWeight: '800',
    color: brand.color.yellow,
    textTransform: 'uppercase'
  },
  btnContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  }
})

const {
  feedback: { route: feedbackRoute }
} = ROUTES_NAMES

const TeamInvitesOverlay = (props) => {
  const { onClick, onClose, visible, teamName, teamLogo, adminName, isHandlingInvite } = props
  const responsive = useResponsive()
  const history = useHistory()

  const stateSelectorFunction = ({ profile }) => ({
    profile,
    teamID: profile.team_id,
    team: profile.team,
    userId: profile.userId
  })

  useEffect(() => {
    analytics.logEvent('screenView', { currentScreen: 'teamInviteModal' })
  }, [])

  const { ...storeState } = useSelector(stateSelectorFunction)
  const subscription = storeState.profile?.subscriptions?.[0]
  const isBillable = subscription?.billable
  const content = isBillable
    ? `Oops! You won't be able to join this team until you cancel your current subscription. Please contact OneClick Code to join ${teamName}`
    : `${adminName} has invited you to join their team. Enjoy lifetime access to reports pulled by you and your team all in one place!`

  return (
    <>
      {visible && (
        <Modal
          backgroundStyle={{
            backgroundColor: '#121320',
            backgroundImage: 'none',
            ...responsive({
              lg: {
                borderRadius: 16,
                minHeight: 'unset',
                maxWidth: 500,
                overflow: 'visible'
              }
            })
          }}
          withoutDismissIcon>
          <View style={{ justifyContent: 'space-between', flex: 1 }}>
            <View style={{ flex: 1, justifyContent: 'center' }}>
              <Avatar
                url={teamLogo}
                name={teamName}
                style={{
                  width: 56,
                  height: 56,
                  fontSize: 24,
                  background: '#333440',
                  margin: 'auto'
                }}
                style2={{
                  backgroundImage: `url(${photo})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center'
                }}
              />
              <Text style={[styles.modalTitle]}>You've been invited to join {teamName}</Text>
              <Text style={styles.description}>{content}</Text>
            </View>
            <View>
              {isHandlingInvite ? (
                <View style={styles.btnContainer}>
                  <Spinner />
                </View>
              ) : (
                <>
                  <View>
                    <FormButton
                      title={isBillable ? 'Contact Us' : 'ACCEPT'}
                      style={{
                        marginRight: 20,
                        background: '#207EED',
                        borderRadius: 4
                      }}
                      onClick={async () => {
                        if (isBillable) {
                          history.push(feedbackRoute)
                          onClose()
                        } else {
                          await onClick(true)
                          analytics.logEvent('buttonSelected', {
                            buttonType: 'teamInviteAccept'
                          })
                        }
                      }}
                    />
                    <FormButton
                      title="DECLINE"
                      onClick={async () => {
                        await onClick(false)
                        analytics.logEvent('buttonSelected', {
                          buttonType: 'teamInviteDecline'
                        })
                      }}
                      style={{
                        background: 'transparent',
                        border: '1px solid #207EED',
                        borderRadius: 4
                      }}
                    />
                  </View>
                  <View style={{ alignItems: 'center', marginTop: 20 }}>
                    <Buttons.Flashy
                      onClick={() => {
                        analytics.logEvent('buttonSelected', {
                          buttonType: 'teamInviteSkip'
                        })
                        onClose()
                      }}>
                      <Text style={{ color: '#1AA7FF' }}>Skip For Now</Text>
                    </Buttons.Flashy>
                  </View>
                </>
              )}
            </View>
          </View>
        </Modal>
      )}
    </>
  )
}

const mapStateToProps = ({ profile }) => ({ profile })
const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(TeamInvitesOverlay)
