/* eslint-disable no-underscore-dangle, react/destructuring-assignment */
import React from 'react'
import ReactDOM from 'react-dom'

class ModalPortal extends React.Component {
  constructor(props) {
    super(props)
    this.el = document.createElement('div')
  }

  _bodyScrollPosition = 0

  componentDidMount() {
    this.modalRoot = document.getElementById('modal-root')

    this._bodyScrollPosition = window.scrollY
    const [body] = document.getElementsByTagName('body')
    body.classList.add('modal-open')

    if (this.props.maintainTop) {
      body.style.top = `-${this._bodyScrollPosition}px`
    }

    this.el.style.opacity = '0'
    this.el.style.pointerEvents = 'all'
    this.el.style.transition = 'all 200ms'

    window.setTimeout(() => {
      this.el.style.opacity = '1'
    }, 5)
    // The portal element is inserted in the DOM tree after
    // the Modal's children are mounted, meaning that children
    // will be mounted on a detached DOM node. If a child
    // component requires to be attached to the DOM tree
    // immediately when mounted, for example to measure a
    // DOM node, or uses 'autoFocus' in a descendant, add
    // state to Modal and only render the children when Modal
    // is inserted in the DOM tree.
    this.modalRoot.appendChild(this.el)
  }

  componentWillUnmount() {
    this.modalRoot.removeChild(this.el)
    const [body] = document.getElementsByTagName('body')
    body.classList.remove('modal-open')
    body.style.top = ''
    window.scrollTo(0, this._bodyScrollPosition)
  }

  render() {
    return ReactDOM.createPortal(this.props.children, this.el)
  }
}

export default ModalPortal
